import automationIcon from '@assets/svg/picto-automation.svg'
import contractIcon from '@assets/svg/picto-contract.svg'
import legalIcon from '@assets/svg/picto-legal-constraints.svg'
import paymentIcon from '@assets/svg/picto-payment.svg'
import planningIcon from '@assets/svg/picto-planning.svg'
import replacementIcon from '@assets/svg/picto-replacement.svg'
import transfertIcon from '@assets/svg/picto-transfert.svg'

const functionalities = [
    {
        id: 1,
        media: planningIcon,
        title: 'Création des plannings en 2 heures',
        content:
            'Réduisez le temps que vous passez à construire des plannings grâce à notre plateforme ludique et intuitive.',
    },
    {
        id: 2,
        media: automationIcon,
        title: 'Génération automatique des roulements',
        content:
            "Le logiciel Staffea génère des roulements et les attribue à votre personnel médical, grâce à l'intelligence artificielle et au machine learning.",
    },
    {
        id: 3,
        media: legalIcon,
        title: 'Intégration de vos contraintes réglementaires',
        content:
            "Recevez un décompte précis des heures de vos remplaçants et gérez facilement vos déclarations préalables à l'embauche.",
    },
    {
        id: 4,
        media: paymentIcon,
        title: 'Transmission des éléments de pré-paie à votre logiciel',
        content:
            'Gagnez du temps en interfaçant Staffea à votre logiciel de paie. Il transmet automatiquement vos éléments variables de paie sans ressaisie.',
    },
    {
        id: 5,
        media: replacementIcon,
        title: 'Traitement rapide des demandes de remplacement',
        content:
            "Via l'application mobile, vos collaborateurs indiquent leurs disponibilités pour faciliter les remplacements. Ils sont alertés immédiatement en cas de poste vacant à pourvoir.",
    },
    {
        id: 6,
        media: transfertIcon,
        title: "Bourse d'échanges d'horaires entre salariés",
        content:
            "Vos collaborateurs peuvent échanger des horaires aisément sur l'application Staffea. Vous recevez les demandes d'échanges pour les valider ou non.",
    },
    {
        id: 7,
        media: contractIcon,
        title: "Génération des contrats de travail et d'intérim",
        content:
            "Notre logiciel d'optimisation de planning intègre vos contrats de travail et d'intérim. Dématérialisez vos embauches avec la signature électronique.",
    },
]

export default functionalities
