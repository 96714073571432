import imgMobileAvailabilities from '@assets/images/mobile-availabilities.png'
import imgMobileExchange from '@assets/images/mobile-exchange.png'
import imgMobileNotifications from '@assets/images/mobile-notifications.png'
import imgMobilePlanning from '@assets/images/mobile-planning.png'

const content = [
    {
        title: 'Accès et consultation de son propre planning',
        description: 'Votre personnel médical consulte son planning à tout moment, via l’application Staffea.',
        image: imgMobilePlanning,
        alt: 'Staffea, application mobile consultation du planning',
    },
    {
        title: 'Partage de ses disponibilités',
        description: 'Idéal pour simplifier les remplacements et préserver les équipes.',
        image: imgMobileAvailabilities,
        alt: 'Staffea, application mobile affichage des disponibilités',
    },
    {
        title: 'Echange de jours avec son équipe',
        description:
            "Les infirmiers, secrétaires et techniciens de laboratoire demandent des modifications d'horaires facilement.",
        image: imgMobileExchange,
        alt: 'Staffea, application mobile échange de jours entre collaborateurs',
    },
    {
        title: 'Notifications push immédiates',
        description: 'Le personnel reçoit chaque modification de planning en temps réel, pour plus de réactivité.',
        image: imgMobileNotifications,
        alt: 'Staffea application mobile affichage notification push',
    },
]

export default content
