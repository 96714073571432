import imgAutomatic from '@assets/images/img-automatic.png'
import imgSync from '@assets/images/img-sync-plannings.png'
import imgTsqPlanning from '@assets/images/img-tailor-intelligent-planning.png'
import imgPlanning from '@assets/images/img-uptodate-planning.png'

const content = [
    {
        title: 'Planning intelligent',
        description:
            "Vos plannings n'ont jamais été aussi faciles à construire. Grâce à l'intelligence artificielle et au machine learning, Staffea vous suggère automatiquement des roulements selon les besoins de votre laboratoire et les préférences de vos collaborateurs.",
        image: imgTsqPlanning,
        alt: "Staffea, application web écran d'automatisation des plannings",
    },
    {
        title: 'Gain de temps administratif',
        description:
            "Gagnez en efficacité et travaillez plus sereinement avec la dématérialisation de vos plannings. Toutes vos tâches sont intégrées et mises à jour instantanément sur l'application Staffea.",
        image: imgSync,
        alt: 'Staffea, application web et mobile écrans de gestion en temps réél du planning',
    },
    {
        title: 'Planning à jour',
        description:
            'Intégrez toutes les heures réellement effectuées dans votre logiciel grâce au système de pointage automatique, pour faciliter le calcul des éléments variables de paie.',
        image: imgPlanning,
        alt: 'Staffea, application web écran de gestion du planning des managers',
    },
    {
        title: 'Maintenance automatique',
        description:
            'Incluses dans votre abonnement, les mises à jour de Staffea sont réalisées par notre équipe technique. Ne vous embêtez plus avec la maintenance et les sauvegardes !',
        image: imgAutomatic,
        alt: "Staffea, application web écran d'accueil avec notifications de mise à jour",
    },
]

export default content
