const features = [
    'Créez vos trames de planning en 2H',
    'Recherchez des remplaçants selon les disponibilités des collaborateurs',
    'Notifiez automatiquement vos salariés des modifications de planning',
    'Utilisez le système de pointage pour le calcul des éléments variables de paie',
    'Facilitez les embauches avec les modèles de contrat et la signature électronique',
    "Assurez-vous d'avoir des plannings conformes",
]

export default features
